import React from 'react';
// Styles
import ButtonElementStyles from './styles';

const Button = ({ data }) => (
  <ButtonElementStyles
    className={`${
      data.print
        ? 'component-button-print'
        : null
    }`}
  >
    <div
      className={`component component-buttons component-background_${
        data.componentBackground ? data.componentBackground : null
      } ${
        data.componentStyleClasses ? data.componentStyleClasses.join(' ') : null
      }`}
    >
      <div className="component-buttons-content">
        {data.buttonLinks && !data.print && (
          <a
            className={`component-button button-color_${
              data.buttonLinks && data.buttonLinks[0].buttonColor
                ? data.buttonLinks[0].buttonColor
                : null
            }`}
            href={data.buttonLinks[0].relativePathLink}
            target={data.buttonLinks[0].openNewTab ? '_blank' : '_self'}
          >
            {data.buttonText ? data.buttonText : data.buttonLinks[0].linkText}
          </a>
        )}
        {data.print && (
          <input
            type="button"
            className="component-button button-color_Green"
            onClick={() => window.print()}
            value="Print"
          />
        )}
      </div>
    </div>
  </ButtonElementStyles>
);

export default Button;
