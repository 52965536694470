import React from 'react';

import Button from '../../elements/button';

const Buttons = ({ data }) => (
  <div className="component componentType_componentButtons">
    <Button data={data} />
  </div>
);

export default Buttons;
