import styled from 'styled-components';
import { theme } from '../../theme/theme-vars';

const ButtonElementStyles = styled.div`
  > .component > .component-buttons > .component-buttons-content {
    max-width: ${theme.components.componentStandardWidthMax};
    margin: 0 auto;
  }

  .component-buttons {
    text-align: center;
    padding: 40px 20px 20px;

    &.Padding-Top-None {
      padding-top: 0;
    }

    &.Padding-Bottom-None {
      padding-bottom: 0;
    }

    &.Font-Size-Large {
      .component-buttons-content {
        a.component-button {
          margin-top: 30px !important;
          font-size: 30px !important;
          padding-left: 40px !important;
          padding-right: 40px !important;
        }
      }
    }

    .component-buttons-content {
      a.component-button {
        margin: 0 20px 20px;
      }
    }
  }

  &.component-button-print {
    @media print {
      display: none !important;
    }
  }
`;
export default ButtonElementStyles;
